div {
    display: block;
}

.calc h3 {
    margin-top: 0;
}

h3, .h3{
    font-size: 26px;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: normal;
}

.bold {
    font-weight: 700
}

.calc-input { 
    width: 0% !important;
    height: 40px !important;
}

.mb30 {
    margin-bottom: 30px;
}

ul .style-dics{
    list-style: disc;
}


ul li .gold{
    color: gold;
  }

.ml50 {
    margin-left: 50px;
} 

.cal-footer{
    margin: 20px 0 !important
}

/* .row{
    margin-bottom: 25px;
} */